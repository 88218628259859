<template>
  <div class="gnb_wrap">
    <div class="gnb_tab_area">
      <div class="gnb_tab">
        <div><nuxt-link to="/main/today" active-class="on">TODAY</nuxt-link></div>
        <div><nuxt-link to="/main/new" active-class="on">신상품</nuxt-link></div>
        <div><nuxt-link to="/main/best" active-class="on">베스트</nuxt-link></div>
        <div><nuxt-link to="/main/event" active-class="on">이벤트</nuxt-link></div>
        <div><nuxt-link to="/category?id=15" active-class="on">아울렛</nuxt-link></div>
        <div><nuxt-link to="/main/community" active-class="on">커뮤니티</nuxt-link></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabMenu",
};
</script>
