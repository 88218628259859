<template>
  <component :is="component" />
</template>

<script>
import Desktop from "@/components/pages/main/Desktop";
import Mobile from "@/components/pages/main/Mobile";

export default {
  components: { Desktop, Mobile },

  middleware: [
    ({ route, redirect, $pageUtil }) => {
      if (route.path === "/main") {
        //url param 유지하면서 redirect
        return redirect(`/main/today${$pageUtil.getUrlQueryString(route.query)}`);
      }
    }
  ],

  fetch({ store }) {
    store.commit("layout/setMobileHeader", {
      headerComponentName: "main",
      routeName: "메인",
      hasFooter: true,
    });
  },

  computed: {
    component() {
      return this.$device.isMobile ? "mobile" : "desktop";
    },
  }
};
</script>
